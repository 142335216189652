/**
 * Copyright Schrodinger, LLC
 *
 * @fileoverview Module for text constants that are user-facing (panel titles, field names, etc.)
 */
const bbTextConsts = {};

/** @const */
bbTextConsts.and = 'and';
/** @const */
bbTextConsts.addAll = 'Add All';
/** @const */
bbTextConsts.addAnotherRule = '+ Add Another Rule';
/** @const */
bbTextConsts.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
/** @const */
bbTextConsts.auto = 'Auto';
/** @const */
bbTextConsts.betweenInclusive = 'between (inclusive)';
/** @const */
bbTextConsts.cancelButton = 'Cancel';
/** @const */
bbTextConsts.choose = 'Choose';
/** @const */
bbTextConsts.chooseLiveReport = 'Choose LiveReport...';
/** @const */
bbTextConsts.clearAll = 'Clear All Rules';
/** @const */
bbTextConsts.compound = 'Compound';
/** @const */
bbTextConsts.compounds = bbTextConsts.compound + 's';
/** @const */
bbTextConsts.material = 'Material';
/** @const */
bbTextConsts.materials = bbTextConsts.material + 's';
/** @const */
bbTextConsts.device = 'Device';
/** @const */
bbTextConsts.devices = bbTextConsts.device + 's';
/** @const */
bbTextConsts.entity = '${Entity}';
/** @const */
bbTextConsts.entities = '${Entities}';
/** @const */
bbTextConsts.entityText = 'Entity';
/** @const */
bbTextConsts.deleteButton = 'Delete';
/** @const */
bbTextConsts.equalTo = 'equal to (=)';
/** @const */
bbTextConsts.greaterThan = 'greater than (>)';
/** @const */
bbTextConsts.greaterThanEqualTo = '\u{2265}';
/** @const */
bbTextConsts.lessThan = 'less than (<)';
/** @const */
bbTextConsts.okButton = 'OK';
/** @const */
bbTextConsts.reactant = 'Reactant';
/** @const */
bbTextConsts.type = 'Type';
/** @const */
bbTextConsts.blankLiveReportText = 'Blank LiveReport';
/** @const */
bbTextConsts.privateLiveReportTooltipText = 'This LiveReport is private - it will not be listed in the list of LiveReports for anyone but you until you turn off this setting.';
/** @const */
bbTextConsts.otherUsersViewingLiveReportText = ' others viewing LiveReport';
/** @const */
bbTextConsts.createLiveReportTooltipText = 'Create a new LiveReport';
/** @const */
bbTextConsts.openLiveReport = 'Open LiveReport';
/** @const */
bbTextConsts.openingLiveReport = 'Opening LiveReport';
/** @const */
bbTextConsts.createNewLiveReportText = 'Create New LiveReport';
/** @const */
bbTextConsts.createNewFolderText = 'Create Folder';
/** @const */
bbTextConsts.createNewFolderTooltipText = 'Create new folder';
/** @const */
bbTextConsts.createNewFormula = 'Create Formula';
/** @const */
bbTextConsts.createNew = 'NEW';
/** @const */
bbTextConsts.createFreeformColumn = 'Create New Column';
/** @const */
bbTextConsts.defineNewMpo = 'Define new MP Profile';
/** @const */
bbTextConsts.addToLiveReport = 'Add to LiveReport';
/** @const */
bbTextConsts.addToMpp = 'Add to MPP';
/** @const */
bbTextConsts.edit = 'Edit';
/** @const */
bbTextConsts.view = 'View';
/** @const */
bbTextConsts.clone = 'Clone';
/** @const */
bbTextConsts.saveButton = 'Save';
/** @const */
bbTextConsts.ok = 'Ok';
/** @const */
bbTextConsts.plus = '+';
/** @const */
bbTextConsts.ellipsis = '...';
/** @const */
bbTextConsts.selected = ' SELECTED';
/** @const */
bbTextConsts.compoundsSelected = ' COMPOUNDS SELECTED';
/** @const */
bbTextConsts.copyEntityTo = 'Copy to';
/** @const */
bbTextConsts.useCompoundIn = 'Use in';
/** @const */
bbTextConsts.copyNEntitiesTo = 'Copy ${count} ${Entities} to';
/** @const */
bbTextConsts.removeEntity = 'Remove';
/** @const */
bbTextConsts.removeNEntities = 'Remove ${count} ${Entities}';
/** @const */
bbTextConsts.removeEntityTooltip = 'Remove selected ${entity} rows from this LiveReport.  The ${entities} will remain registered in the system and can always be added again.';
/** @const */
bbTextConsts.commentEntity = 'Comment';
/** @const */
bbTextConsts.subscribeTooltip = 'Receive daily email summaries with information about which ${entities}, data, or other information in this LiveReport have changed.';
/** @const */
bbTextConsts.commentNEntities = 'Comment on ${count} ${Entities}';
/** @const */
bbTextConsts.commentEntitiesTooltip = 'View and Add comments to the selected ${entity}(s).';
/** @const */
bbTextConsts.alignCompound = 'Set alignment...';
/** @const */
bbTextConsts.alignCompoundTooltip = 'Use the selected compound as the basis for a common alignment core that all compounds in the LiveReport should be aligned to.';
/** @const */
bbTextConsts.hideEntity = 'Hide';
/** @const */
bbTextConsts.hideNEntities = 'Hide ${count} ${Entities}';
/** @const */
bbTextConsts.hideCompoundTooltip = 'Hide selected rows temporarily.  You can unhide all rows at any time from the bottom status bar';
/** @const */
bbTextConsts.freezeCompoundTooltip = 'Freeze selected rows temporarily.  You can unfreeze all rows at any time from this same menu.';
/** @const */
bbTextConsts.freezeEntity = 'Freeze';
/** @const */
bbTextConsts.unfreezeEntity = 'Unfreeze';
/** @const */
bbTextConsts.showHiddenNEntities = 'Show ${count} Hidden ${Entity}(s)';
/** @const */
bbTextConsts.hideColumn = 'Hide';
/** @const */
bbTextConsts.hideProperty = 'Hide Property';
/** @const */
bbTextConsts.hideColumns = bbTextConsts.hideColumn + 's';
/** @const */
bbTextConsts.showHiddenNColumns = 'Show ${count} Hidden Column(s)';
/** @const */
bbTextConsts.refreshLiveReport = 'Refresh LiveReport';
/** @const */
bbTextConsts.manageColumns = 'Manage Columns…';
/** @const */
bbTextConsts.removeColumn = 'Remove';
/** @const */
bbTextConsts.removeColumns = 'Remove Columns';
/** @const */
bbTextConsts.renameProperty = 'Rename Property…';
/** @const */
bbTextConsts.reorderColumns = 'Reorder Columns…';
/** @const */
bbTextConsts.renameGroup = 'Rename Group…';
/** @const */
bbTextConsts.groupColumns = 'Group…';
/** @const */
bbTextConsts.ungroupColumn = 'Ungroup';
/** @const */
bbTextConsts.ungroupColumns = bbTextConsts.ungroupColumn + 's';
/** @const */
bbTextConsts.columnDetails = 'View Column Details…';
/** @const */
bbTextConsts.deepChem = 'AutoQ${SAR}/DeepChem...';
/** @const */
bbTextConsts.Sketcher = 'Sketcher';
/** @const */
bbTextConsts.designSketcher = 'Design Sketcher';
/** @const */
bbTextConsts.searchSketcher = 'Search Sketcher';
/** @const */
bbTextConsts.copyCompoundToSketcherDisabled = bbTextConsts.Sketcher + ' (Unavailable - multiple compounds selected)';
/** @const */
bbTextConsts.filter = 'Filter';
/** @const */
bbTextConsts.addFilter = 'Add to Filter';
/** @const */
bbTextConsts.columnFilterDisabled = 'Filtering not available on this column';
/** @const */
bbTextConsts.substructureFilter = 'Substructure Filter';
/** @const */
bbTextConsts.analysisTools = 'Analysis Tools';
/** @const */
bbTextConsts.tools = 'Tools';
/** @const */
bbTextConsts.totalRowEntries = 'Total ${rpeModeName}'; // This is used as suffix while displaying the total row count in the footer. eg: 100 Total Compounds
/** @const */
bbTextConsts.displayedRowEntries = 'Displayed ${rpeModeName}'; // This is used as suffix while displaying the current row count in the footer. eg: 20 Displayed Compounds
/** @const */
bbTextConsts.sar = 'SAR';
/** @const */
bbTextConsts.sarAnalysis = 'SAR Analysis';
/** @const */
bbTextConsts.spr = 'SPR';
/** @const */
bbTextConsts.editFormula = 'Edit Formula…';
/** @const */
bbTextConsts.viewFormula = 'View Formula…';
/** @const */
bbTextConsts.editFreeform = 'Edit Freeform Column…';
/** @const */
bbTextConsts.viewFreeform = 'View Freeform Column…';
/** @const */
bbTextConsts.editFreeformColumnNotificationMessage = 'Editing Freeform Column…';
/** @const */
bbTextConsts.editFormulaMenuItemToolTip = 'Edit this formula';
/** @const */
bbTextConsts.editFreeformMenuItemToolTip = 'Edit this freeform column';
/** @const */
bbTextConsts.viewFreeformMenuItemToolTip = 'View this freeform column';
/** @const */
bbTextConsts.editMpo = 'Edit MPO…';
/** @const */
bbTextConsts.viewMpo = 'View MPO…';
/** @const */
bbTextConsts.mpoSuffix = ' (MPO)';
/** @const */
bbTextConsts.customColoring = 'Custom coloring…';
/** @const */
bbTextConsts.applyCustomColoring = 'Apply custom coloring';
/** @const */
bbTextConsts.freezeColumn = 'Freeze';
/** @const */
bbTextConsts.freezeColumns = bbTextConsts.freezeColumn + 's';
/** @const */
bbTextConsts.unfreezeColumn = 'Unfreeze';
/** @const */
bbTextConsts.unfreezeColumns = bbTextConsts.unfreezeColumn + 's';
/** @const */
bbTextConsts.coloringRules = 'Color…';
/** @const */
bbTextConsts.coloringRulesSubMenu = 'Color';
/** @const */
bbTextConsts.copyColorRules = 'Copy';
/** @const */
bbTextConsts.pasteColorRules = 'Paste';
/** @const */
bbTextConsts.editColorRules = 'Edit Coloring Rules…';
/** @const */
bbTextConsts.coloringRulesOfMpoMessage =
  'This is a Multi-Parameter Optimization column whose colors are controlled by special logic shared across the system.  To change this special logic, you may <a class="mpo-edit-link">edit</a> the Multi Parameter Optimization.<br><br>Click the X button on the top right to close this message.';
/** @const */
bbTextConsts.columnColorRules = 'Coloring Rules - ${columnName}';
/** @const */
bbTextConsts.applyingColoringRules = 'Applying coloring rules';
/** @const */
bbTextConsts.logScaleColoring = 'Log Scale Coloring';
/** @const */
bbTextConsts.coloringRulesConvertTooltip =
  'Convert rules between categorical and range';
/** @const */
bbTextConsts.coloringRulesConvertMenuItem =
  'Convert rules to ';
/** @const */
bbTextConsts.coloringRulesConfirmConvertTitle =
  'Convert Rule';
/** @const */
bbTextConsts.coloringRulesConfirmConvertMessage =
  'Converting the rule types will reset all rules.\n\nAre you sure you wish to continue?';
/** @const */
bbTextConsts.color = 'Color:';
/** @const */
bbTextConsts.cellIs = 'Cell is:';
/** @const */
bbTextConsts.cellContains = 'Cell contains:';
/** @const */
bbTextConsts.cellNotContains = 'Cell does not contain:';
/** @const */
bbTextConsts.addableColum = 'Column';
/** @const */
bbTextConsts.addableColumns = 'Columns';
/** @const */
bbTextConsts.database = 'Database';
/** @const */
bbTextConsts.databases = bbTextConsts.database + 's';
/** @const */
bbTextConsts.column = 'Column';
/** @const */
bbTextConsts.columns = `${bbTextConsts.column}s`;
/** @const */
bbTextConsts.columnAlias = 'Column Alias';
/** @const */
bbTextConsts.columnAliases = bbTextConsts.columnAlias + 'es';
/** @const */
bbTextConsts.columnFolder = 'Column Folder';
/** @const */
bbTextConsts.columnFolders = 'Column Folders';
/** @const */
bbTextConsts.columnGroup = 'Column Group';
/** @const */
bbTextConsts.columnGroups = bbTextConsts.columnGroup + 's';
/** @const */
bbTextConsts.columnNode = 'Column Node';
/** @const */
bbTextConsts.columnNodes = bbTextConsts.columnNode + 's';
/** @const */
bbTextConsts.columnLeaf= 'Column Leaf';
/** @const */
bbTextConsts.columnLeaves = 'Column Leaves';
/** @const */
bbTextConsts.columnDescriptor = 'Column Descriptor';
/** @const */
bbTextConsts.columnDescriptors = bbTextConsts.columnDescriptor + 's';
/** @const */
bbTextConsts.compoundPropertyColumn = 'Compound Property Column';
/** @const */
bbTextConsts.compoundPropertyColumns = bbTextConsts.compoundPropertyColumn + 's';
/** @const */
bbTextConsts.experimentRun = 'Experiment Run';
/** @const */
bbTextConsts.glideDockResult = 'Glide Dock Result';
/** @const */
bbTextConsts.glideDockResults = bbTextConsts.glideDockResult + 's';
/** @const */
bbTextConsts.glideDockJob = 'Glide Dock Job';
/** @const */
bbTextConsts.glideDockJobs = bbTextConsts.glideDockJob + 's';
/** @const */
bbTextConsts.ligandDesignerAsyncJob = 'Ligand Designer Async Job';
/** @const */
bbTextConsts.ligandDesignerAsyncJobs = bbTextConsts.ligandDesignerAsyncJob + 's';
/** @const */
bbTextConsts.ligandDesignerConfiguration = 'Ligand Designer Configuration';
/** @const */
bbTextConsts.ligandDesignerConfigurations = bbTextConsts.ligandDesignerConfiguration + 's';
/** @const */
bbTextConsts.ligandDesignerDockJob = 'Ligand Designer Dock Job';
/** @const */
bbTextConsts.ligandDesignerDockJobs = bbTextConsts.ligandDesignerDockJob + 's';
/** @const */
bbTextConsts.ligandDesignerMinimizeJob = 'Ligand Designer Minimize Job';
/** @const */
bbTextConsts.ligandDesignerMinimizeJobs = bbTextConsts.ligandDesignerMinimizeJob + 's';
/** @const */
bbTextConsts.glideSession = 'Glide Session';
/** @const */
bbTextConsts.glideSessions = bbTextConsts.glideSession + 's';
/** @const */
bbTextConsts.ligandDesignerSession = 'Glide Session';
/** @const */
bbTextConsts.ligandDesignerSessions = bbTextConsts.ligandDesignerSession + 's';
/** @const */
bbTextConsts.reaction = 'Reaction';
/** @const */
bbTextConsts.reactions = bbTextConsts.reaction + 's';
/** @const */
bbTextConsts.scaffold = 'Scaffold';
/** @const */
bbTextConsts.scaffolds = bbTextConsts.scaffold + 's';
/** @const */
bbTextConsts.threeDStructureGroup = 'Three-D Structure Group';
/** @const */
bbTextConsts.threeDStructureGroups = bbTextConsts.threeDStructureGroup + 's';
/** @const */
bbTextConsts.structure = 'Structure';
/** @const */
bbTextConsts.structures = bbTextConsts.structure + 's';
/** @const */
bbTextConsts.query = 'Query';
/** @const */
bbTextConsts.queries = 'Queries';
/** @const */
bbTextConsts.displayColumnFolder = 'Display Column Folder';
/** @const */
bbTextConsts.displayColumnFolders = bbTextConsts.displayColumnFolder + 's';
/** @const */
bbTextConsts.hideColumnTooltip = 'Hide this column temporarily. To reveal it again, open the Columns list in any column menu and click the checkbox next to its name.';
/** @const */
bbTextConsts.hidePropertyTooltip = 'Hide this property temporarily. To reveal it again, use the Configure Tiles dialog.';
/** @const */
bbTextConsts.manageColumnsTooltip = 'Manage one or more columns in this LiveReport.';
/** @const */
bbTextConsts.removeColumnTooltip = 'Removes this column from the LiveReport.';
/** @const */
bbTextConsts.removeMpoDesirabilityScoreColumnTooltip = 'Remove the MPO column.';
/** @const */
bbTextConsts.reorderColumnsTooltip = 'Reorder one or more columns in this LiveReport.';
/** @const */
bbTextConsts.renameGroupTooltip = 'Rename this group';
/** @const */
bbTextConsts.groupColumnsTooltip = 'Group the selected columns together.';
/** @const */
bbTextConsts.ungroupColumnsTooltip = 'Ungroup the selected columns.';
/** @const */
bbTextConsts.invalidSelection = 'Invalid selection';
/** @const */
bbTextConsts.customAlignmentTitle = 'Set Alignment for Compound Structures';
/** @const */
bbTextConsts.customAlignmentHeader = 'Keep all compounds in this LiveReport aligned to the following core:';
/** @const */
bbTextConsts.customAlignmentInfo = 'Note that scaffold alignment resulting from ${SAR} Analysis will take precedence over this setting';
/** @const */
bbTextConsts.asyncResponse = 'Async Status';
/** @const */
bbTextConsts.showText = 'Show as text';
/** @const */
bbTextConsts.showStereoCenters = 'Show Stereo Annotations';
/** @const */
bbTextConsts.showAlignment = 'Set Alignment…';
/** @const */
bbTextConsts.showAlignmentTooltip = 'Align all compounds in the LiveReport to a common core';
/** @const */
bbTextConsts.exportRequest = 'Export Request';
/** @const */
bbTextConsts.exportText = 'Export';
/** @const */
bbTextConsts.exportPSE = 'To PyMOL (.pse)';
/** @const */
bbTextConsts.exportMAE = 'To Maestro (.mae)';
/** @const */
bbTextConsts.exportMAEGZ = 'To Maestro (.maegz)';
/** @const */
bbTextConsts.exportMOL2 = 'To Other Viewer (.mol2)';
/** @const */
bbTextConsts.exportScreenshot = 'To Static Image (.png)';
/** @const */
bbTextConsts.WebMolScreenshot = 'WebPyMolScreenshot.png';
/** @const */
bbTextConsts.exportTooltip = 'Export the current 3D scene to other tools';
/** @const */
bbTextConsts.exportMol2Tooltip = 'The mol2 format doesn\'t work well in PyMOL';
/** @const */
bbTextConsts.exportLabel = 'Export';
/** @const */
bbTextConsts.exportAll = 'Entire LiveReport';
/** @const */
bbTextConsts.exportEntireReport = 'Export Report';
/** @const */
bbTextConsts.exportSelected = 'Export as';
/** @const */
bbTextConsts.exportMaxSelectedRow = 'Export ${Entities} (must have < 150 selected)';
/** @const */
bbTextConsts.model = 'Model';
/** @const */
bbTextConsts.models = bbTextConsts.model + 's';
/** @const */
bbTextConsts.liveReportResultFragment = 'LiveReport Result Fragment';
/** @const */
bbTextConsts.liveReportResultFragments = bbTextConsts.liveReportResultFragment + 's';
/** @const */
bbTextConsts.liveReportResultRowInfo = 'LiveReport Result Row';
/** @const */
bbTextConsts.liveReportResultRowInfos = bbTextConsts.liveReportResultRowInfo + 's';
/** @const */
bbTextConsts.liveReportResultVersion = 'LiveReport Result Version';
/** @const */
bbTextConsts.liveReportResultVersions = bbTextConsts.liveReportResultVersion + 's';
/** @const */
bbTextConsts.newLiveReport = 'New ' + bbTextConsts.liveReport;
/** @const */
bbTextConsts.liveReport = 'LiveReport';
/** @const */
bbTextConsts.copyToExistingLiveReport = 'Existing LiveReport...';
/** @const */
bbTextConsts.copyToNewLiveReport = 'New LiveReport...';
/** @const */
bbTextConsts.liveReports = bbTextConsts.liveReport + 's';
/** @const */
bbTextConsts.liveReportMetadata = 'LiveReport Metadata';
/** @const */
bbTextConsts.liveReportMetadatas = bbTextConsts.liveReportMetadata;
/** @const */
bbTextConsts.plot = 'Plot';
/** @const */
bbTextConsts.plots = bbTextConsts.plot + 's';
/** @const */
bbTextConsts.complex = 'Complex';
/** @const */
bbTextConsts.complexes = bbTextConsts.complex + 'es';
/** @const */
bbTextConsts.project = 'Project';
/** @const */
bbTextConsts.projects = bbTextConsts.project + 's';
/** @const */
bbTextConsts.dataset = 'Database/Dataset';
/** @const */
bbTextConsts.pymolSession = 'PyMOL Session';
/** @const */
bbTextConsts.pymolSessions = bbTextConsts.pymolSession + 's';
/** @const */
bbTextConsts.rationale = 'Rationale';
/** @const */
bbTextConsts.rationales = bbTextConsts.rationale + 's';
/** @const */
bbTextConsts.realtimePropertyResult = 'Realtime Property Result';
/** @const */
bbTextConsts.realtimePropertyResults = bbTextConsts.realtimePropertyResult + 's';
/** @const */
bbTextConsts.subscription = 'Subscription';
/** @const */
bbTextConsts.subscriptions = bbTextConsts.subscription + 's';
/** @const */
bbTextConsts.comment = 'Comment';
/** @const */
bbTextConsts.comments = bbTextConsts.comment + 's';
/** @const */
bbTextConsts.commentPostField = 'Enter a comment for the selected ${entity}(s). Use [Link Title | Link URL] to include hyperlinks in your comment.  Please be clear, concise, and focus on the facts when commenting.  Use company values as a guide.';
/** @const */
bbTextConsts.commentSelectedEntitiesRadioelectedEntitiesRadio = 'Selected ${Entity}(s)';
/** @const */
bbTextConsts.commentAllEntitiesRadio = 'All ${Entities}';
/** @const */
bbTextConsts.tag = 'Folder';
/** @const */
bbTextConsts.tags = bbTextConsts.tag + 's';
/** @const */
bbTextConsts.favoriteAssay = 'Favorite Assay';
/** @const */
bbTextConsts.favoriteAssays = bbTextConsts.favoriteAssay + 's';
/** @const */
bbTextConsts.favoriteExternalProperty = 'Favorite External Property';
/** @const */
bbTextConsts.favoriteExternalProperties = 'Favorite External Properties';
/** @const */
bbTextConsts.favoriteColumn = 'Favorite Column';
/** @const */
bbTextConsts.favoriteColumns = bbTextConsts.favoriteColumns + 's';
/** @const */
bbTextConsts.plexusResponse = 'Plexus Response';
/** @const */
bbTextConsts.plexusResponses = bbTextConsts.plexusResponse + 's';
/** @const */
bbTextConsts.useInEnumerationRunner = 'Enumeration';
/** @const */
bbTextConsts.importSettings = 'Import Settings';
/** @const */
bbTextConsts.importData = 'Import Data';
/** @const */
bbTextConsts.defaultProjectName = 'LiveDesign LiveReports';
/** @const */
bbTextConsts.columnFilter = 'Base Column Filter';
/** @const */
bbTextConsts.columnFilters = bbTextConsts.columnFilter + 's';
/** @const */
bbTextConsts.textFilter = 'Text Filter';
/** @const */
bbTextConsts.textFilters = bbTextConsts.textFilter + 's';
/** @const */
bbTextConsts.rangeFilter = 'Range Filter';
/** @const */
bbTextConsts.rangeFilters = bbTextConsts.rangeFilter + 's';
/** @const */
bbTextConsts.mpo = 'MPO';
/** @const */
bbTextConsts.mpos = bbTextConsts.mpo + 's';
/** @const */
bbTextConsts.history= 'History';
/** @const */
bbTextConsts.pldbStructure = 'PLDB Structure';
/** @const */
bbTextConsts.pldbStructures = bbTextConsts.pldbStructure + 's';
/** @const */
bbTextConsts.asyncTask = 'async task';
/** @const */
bbTextConsts.asyncTasks = bbTextConsts.asyncTask + 's';
/** @const */
bbTextConsts.auditLog = 'Audit Log';
/** @const */
bbTextConsts.auditLogs = bbTextConsts.auditLog + 's';
/** @const */
bbTextConsts.RGroups = 'R-groups';
/** @const */
bbTextConsts.enumeration_reactant = 'Enumeration Reactant';
/** @const */
bbTextConsts.enumeration_r_group = 'Enumeration R-Group';
/** @const */
bbTextConsts.enumeration_r_groups = bbTextConsts.enumeration_r_group + 's';
/** @const */
bbTextConsts.enumeration_reactants = bbTextConsts.enumeration_reactant + 's';
/** @const */
bbTextConsts.reactionEnumerationWizard = 'Reaction Enumeration Wizard';
/** @const */
bbTextConsts.scaffoldEnumerationWizard = 'Scaffold Enumeration Wizard';
/** @const */
bbTextConsts.viz3DVisualization = 'Viz3D Visualization';
/** @const */
bbTextConsts.viz3DVisualizations = bbTextConsts.viz3DVisualization + 's';
/** @const */
bbTextConsts.wsReactionPreview = 'Ws Reaction Preview';
/** @const */
bbTextConsts.wsRGroupEnumerationPreview = 'Ws R-Group Enumeration Preview';
/** @const */
bbTextConsts.wsRGroupEnumerationPreviews = bbTextConsts.wsRGroupEnumerationPreview + 's';
/** @const */
bbTextConsts.wsReactionPreviews = bbTextConsts.wsReactionPreview + 's';
/** @const */
bbTextConsts.enumerationReactionJob = 'Enumeration Reaction Job';
/** @const */
bbTextConsts.enumerationRGroupJob = 'Enumeration R-Group Job';
/** @const */
bbTextConsts.enumerationRGroupJobs = bbTextConsts.enumerationRGroupJob + 's';
/** @const */
bbTextConsts.enumerationReactionJobs = bbTextConsts.enumerationReactionJob + 's';
/** @const */
bbTextConsts.formula = 'Formula';
/** @const */
bbTextConsts.formulas = bbTextConsts.formula + 's';
/** @const */
bbTextConsts.freeformColumn = 'Freeform Column';
/** @const */
bbTextConsts.freeformColumns = bbTextConsts.freeformColumn + 's';
/** @const */
bbTextConsts.systemColumn = 'Default Column';
/** @const */
bbTextConsts.systemColumns = bbTextConsts.systemColumn + 's';
/** @const */
bbTextConsts.observation = 'Observation';
/** @const */
bbTextConsts.observations = bbTextConsts.observation + 's';
/** @const */
bbTextConsts.notAvailable = 'Not Available';
/** @const */
bbTextConsts.existingLiveReports = 'Existing ' + bbTextConsts.liveReports;
/** @const */
bbTextConsts.logRecord = 'Log Record';
/** @const */
bbTextConsts.logRecords = 'Log Records';
/** @const */
bbTextConsts.activeReportUser = 'Active LiveReport User';
/** @const */
bbTextConsts.activeReportUsers = bbTextConsts.activeReportUser + 's';
/** @const */
bbTextConsts.alert = 'Alert';
/** @const */
bbTextConsts.alerts = bbTextConsts.alert + 's';
/** @const */
bbTextConsts.aggregateObservation = 'Aggregate Observation';
/** @const */
bbTextConsts.aggregateObservations = bbTextConsts.aggregateObservation + 's';
/** @const */
bbTextConsts.protocol = 'Protocol';
/** @const */
bbTextConsts.lotNumber = 'Lot Number';
/** @const */
bbTextConsts.concentration = 'Concentration';
/** @const */
bbTextConsts.concentrationUnits = 'Concentration Units';
/** @const */
bbTextConsts.notebook = 'Notebook';
/** @const */
bbTextConsts.notebookPage = 'Notebook Page';
/** @const */
bbTextConsts.batch = 'Batch';
/** @const */
bbTextConsts.date = 'Date';
/** @const */
bbTextConsts.fileName = 'File Name';
/** @const */
bbTextConsts.fileId = 'File ID';
/** @const */
bbTextConsts.uploader = 'Uploader';
/** @const */
bbTextConsts.uploaded = 'Uploaded';
/** @const */
bbTextConsts.invalidDate = 'Invalid Date';
/** @const */
bbTextConsts.noDetails = 'No details available';
/** @const */
bbTextConsts.protConjoiner = ' Prot ';
/** @const */
bbTextConsts.lotConjoiner = ' Lot ';
/** @const */
bbTextConsts.atConjoiner = ' at ';
/** @const */
bbTextConsts.layout = 'Layout';
/** @const */
bbTextConsts.layouts = bbTextConsts.layout + 's';
/** @const */
bbTextConsts.landingPageBookmark = 'Landing Page Bookmark';
/** @const */
bbTextConsts.landingPageBookmarks = bbTextConsts.landingPageBookmark + 's';

/** @const */
bbTextConsts.visualize = 'Visualize';
/** @const */
bbTextConsts.pdbWithComplexTooltip = 'View the row\'s structure in the viewer gadget';
/** @const */
bbTextConsts.updatingLiveReport = 'Updating ' + bbTextConsts.liveReport + ' ...';

/** @const */
bbTextConsts.corporateId = 'Corporate Id';
/** @const */
bbTextConsts.propertyId = 'Property Id';
/** @const */
bbTextConsts.taskId = 'Task Id';

/** @const */
bbTextConsts.projectPickerTitle = 'Select Project';
/** @const */
bbTextConsts.feedbackButtonTitle = 'Give Feedback!';

/** Tree Views */
/** @const */
bbTextConsts.noLiveReportsFoundText = 'No LiveReports matched your filter.';
/** @const */
bbTextConsts.liveReportSearchPlaceholderText = 'Search for LiveReports by name';
/** @const */
bbTextConsts.liveReportSortByFolderTip = 'Sort by folder';
/** @const */
bbTextConsts.liveReportSortByNameTip = 'Sort by name';
/** @const */
bbTextConsts.liveReportSortByDateTip = 'Sort by last updated date';
/** @const */
bbTextConsts.liveReportSortByOwnerTip = 'Sort by author';
/** @const */
bbTextConsts.liveReportDateText = 'Last edited on ';
/** @const */
bbTextConsts.liveReportDragDropMessage = 'Hold and drag to move to folder';

/** LiveReport dialogs */
/** @const */
bbTextConsts.newLiveReportTitle = 'Create New LiveReport';
/** @const */
bbTextConsts.deleteLiveReportTitle =
  'Delete LiveReport';
/** @const */
bbTextConsts.deleteLiveReportFieldName =
  'Are you sure that you want to delete the LiveReport "{liveReportTitle}"?';
/** @const */
bbTextConsts.removeCompoundsTitle =
  'Remove ${Entities}';
/** @const */
bbTextConsts.removeCompoundFieldName =
  'Are you sure that you want to remove the ${entity} from the LiveReport "{liveReportTitle}"?';
/** @const */
bbTextConsts.removeCompoundsFieldName =
  'Are you sure that you want to remove ${count} ${entities} from the LiveReport "{liveReportTitle}"?';
/** @const */
bbTextConsts.removeAllCompounds =
  'Are you sure that you want to remove ALL ${entities} from the Active LiveReport (including hidden and filtered ${entities})?';
/** @const */
bbTextConsts.newLiveReportRationaleFieldName =
  'Default rationale (optional):';
/** @const */
bbTextConsts.updateLiveReportRationaleTitle =
  'Update LiveReport Rationale';
/** @const */
bbTextConsts.updateLiveReportRationaleFieldName =
  'Default rationale:';
/** @const */
bbTextConsts.updateCompoundRationaleTitle =
  'Update Rationale for ${corporateId}';
/** @const */
bbTextConsts.liveReportRationaleEmptyText =
  'The default rationale will be added to all ${entities} in the live ' +
  'report which have not been given an explicit rationale.';
/** @const */
bbTextConsts.addEditRationale = 'Add/Edit ' + bbTextConsts.rationale;
/** @const */
bbTextConsts.addEditRationaleTooltip = 'Add or edit the rationale behind the selected ${entity}(s).';
/** @const */
bbTextConsts.updateCompoundRationaleEmptyText =
  'Rationale for this ${entity}. Use [Link Title| Link URL] to include hyperlinks.';
/** @const */
bbTextConsts.updateRationalMessage =
  'updating rationale';
/** @const */
bbTextConsts.openNamedLiveReport =
  'Open "{liveReportTitle}"';
/** @const */
bbTextConsts.openAfterCopy = 'open "{targetLiveReportName}" after copy.';
/** @const */
bbTextConsts.mpoDialogTitle = 'Define new Multi-Parameter Optimization';
/** @const */
bbTextConsts.mpoColumnTooltipText =
  '<br><br>This Multi-Parameter Optimization combines several properties (constituents) into one<br>color-coded, aggregate summary of compound quality.  You may edit it<br> to change metrics from the column menu.<br><br>As long as this column is present in the LiveReport, any columns that belong<br>to its set of constituents will be colored according to these metrics.';
/** @const */
bbTextConsts.aggregateExpansionFailed = 'An error occurred while retrieving observations from the backend.';

/** @const */
bbTextConsts.pymolGadgetTitle = 'Structure Visualizer';
/** @const */
bbTextConsts.visualizationGadgetTitle = 'Plot';

/** @const */
bbTextConsts.ImageCellUnavailable = '</div><p>Image not available</p>';

/*
 * PDB Cell contents
 */
/** @const */
bbTextConsts.ThreeDCellView = '<div class=\'pymol-view-image\'></div><p>View 3D</p>';
/** @const */
bbTextConsts.ThreeDCellUnavailable = '</div><p>3D not available</p>';

/** @const */
bbTextConsts.PyMolHelp =
  'Add 3D models to visualize selected compounds:   Click here, then browse ' +
  'or search for any models containing the word \'(3D)\' in the list that ' +
  'appears on the left.';
/** @const */
bbTextConsts.PyMolOverflow = '\'Listing only the first 10 compounds in your ' +
  'selection. To see more compounds here, select their rows exclusively in the ' +
  'LiveReport.\'';
bbTextConsts.PyMolMoreLigands = '... more ligands ...';

/** @const */
bbTextConsts.suggestRefresh =
  'Authentication error, please try refreshing the page';
/** @const */
bbTextConsts.loggedOut = 'You have been logged out of LiveDesign.<br/>' +
  '  You must log back in to continue';
/** @const */
bbTextConsts.loggedOutTitle = 'You must log back in';
/** @const */
bbTextConsts.flushingRecords = 'Logging you out, one moment please...';

/** @const */
bbTextConsts.password = 'Password';
/** @const */
bbTextConsts.rename = 'Rename…';
/** @const */
bbTextConsts.deleteStr = 'Delete';
/** @const */
bbTextConsts.close = 'Close';
/** @const */
bbTextConsts.closeAll = 'Close All';
/** @const */
bbTextConsts.editDefaultRationale = 'Edit Default Rationale…';
/** @const */
bbTextConsts.globalPrefix = '(Global)';

/** @const */
bbTextConsts.inProgress = 'In Progress...';
/** @const */
bbTextConsts.loadingText = 'Loading...';
/** @const */
bbTextConsts.loadingReports = 'Loading LiveReports...';

/** @const */
bbTextConsts.searching = 'Searching ...';
/** @const */
bbTextConsts.searchingForEntities = '${Entity} search in progress...';
/** @const */
bbTextConsts.searchingForLiveReports = 'LiveReport search in progress...';
/** @const */
bbTextConsts.cancelSearch = 'Cancel';
/** @const */
bbTextConsts.searchColumns = 'Search columns';
/** @const */
bbTextConsts.dataAndColumns = 'Data & Columns';
/** @const */
bbTextConsts.properties = 'Properties';
/** @const */
bbTextConsts.searchNoMatch = 'No columns match your search term';
/** @const */
bbTextConsts.clearSearch = 'Clear search';

/** @const */
bbTextConsts.error = 'Error';
/** @const */
bbTextConsts.success = 'Success';
/** @const */
bbTextConsts.invalid = 'Invalid';
/** @const */
bbTextConsts.pending = 'Pending';
/** @const */
bbTextConsts.failed = 'Failed';
/** @const */
bbTextConsts.notAvailableShort = 'N/A';
/** @const **/
bbTextConsts.constituentUnavailableName = '(Unknown Constituent)';

/** @const */
bbTextConsts.dragDropEntity = '1 ${entity} selected';
/** @const */
bbTextConsts.dragDropEntities = '${count} ${entities} selected';

/** @const */
bbTextConsts.dragDropCopyToTabsOrSketcher = '- drag to the sketcher or to any LiveReport tabs above to copy';
/** @const */
bbTextConsts.dragDropCopyToTabsOnly = ' - drag onto any LiveReport tab above to copy';

/*
 * Strings for Data & Columns drawer
 */
bbTextConsts.propertyFolders = {};

/** @const */
bbTextConsts.propertyFolders.mpo = 'Multi-Parameter Optimization';
/** @const */
bbTextConsts.propertyFolders.computationalModel = 'Computational Models';
/** @const */
bbTextConsts.propertyFolders.formulas = 'Formulas';
/** @const */
bbTextConsts.propertyFolders.freeformColumns = 'Freeform Columns';
/** @const */
bbTextConsts.propertyFolders.databaseColumns = 'Other Columns';

/*
 * Strings for Compounds Drawer (sketcher, file import, structure search, etc)
 */
bbTextConsts.compoundsDrawer = {};

/** @const */
bbTextConsts.compoundsDrawer.addIdeaDisabled = 'Cannot add compound ideas to Reactant LiveReports';
/** @const */
bbTextConsts.compoundsDrawer.drawCompoundTitle = 'Draw Compound';
/** @const */
bbTextConsts.compoundsDrawer.fromFileTitle = 'Import from File';
/** @const */
bbTextConsts.compoundsDrawer.publishedCheckboxLabel = 'Publish uploaded ${entities}';
/** @const */
bbTextConsts.compoundsDrawer.searchIDTitle = 'Search by ID';
/** @const */
bbTextConsts.maxResults = 'Max Results:';
/** @const */
bbTextConsts.compoundsDrawer.searchStructureTitle = 'Search by Structure & Similarity';
/** @const */
bbTextConsts.compoundsDrawer.sketcherTitle = 'Draw & Search by Structure';
/** @const */
bbTextConsts.compoundsDrawer.searchIDPlaceholderText = 'Enter one or more IDs separated by commas, spaces, or line breaks (ex: CRA-123456, CRA-654321)';
/** @const */
bbTextConsts.compoundsDrawer.LRSearchByEntitiyIDTooltip = 'Search for LiveReports containing these ${entities}';
/** @const */
bbTextConsts.compoundsDrawer.LRSearchByStructureTooltip = 'Search for LiveReports containing compounds that match this structural query';
/** @const */
bbTextConsts.compoundsDrawer.drawIdeaTooltip = 'Draw ideas to add to the current LiveReport';
/** @const */
bbTextConsts.compoundsDrawer.searchExactTooltip = 'Search the system for the compound sketched above';
/** @const */
bbTextConsts.compoundsDrawer.searchSimilarityTooltip = 'Search the system for compounds similar to the one sketched above';
/** @const */
bbTextConsts.compoundsDrawer.searchSimilarityLabel = 'Similarity: ${percent}%';
/** @const */
bbTextConsts.compoundsDrawer.searchSubstructureTooltip = 'Search the system for compounds containing the substructure sketched above';
/** @const */
bbTextConsts.compoundsDrawer.entitiesFound = '${count} found';
/** @const */
bbTextConsts.dragDropMessage = 'Drag & Drop Compound Structure <span class="invalid">One at a time please</span>';
/** @const */
bbTextConsts.dragDropMessageText = 'Drag & Drop Compound Structure';
/** @const */
bbTextConsts.sarDropMessage = 'Drag new scaffold <span class="invalid">One at a time please</span>';
/** @const */
bbTextConsts.compoundsDrawer.markushEnumeration = 'Markush Enumeration';
/** @const */
bbTextConsts.compoundsDrawer.enumerationInProgress = 'Enumeration is in progress. Compounds will appear in the selected LiveReport when complete.';
/** @const */
bbTextConsts.compoundsDrawer.enumerationFailed = 'Enumeration failed.';
/** @const */
bbTextConsts.compoundsDrawer.enumerationComplete = 'Enumeration and import complete.';
/** @const */
bbTextConsts.compoundsDrawer.activeLiveReport = 'Active LiveReport';

/*
 * LiveReport Search Results
 */
/** @const */
bbTextConsts.liveReportSearchTitle = 'LiveReport Search Results';
/** @const */
bbTextConsts.liveReportSearchResult = '1 LiveReport was found';
/** @const */
bbTextConsts.liveReportSearchResults = '${count} LiveReports were found';
/** @const */
bbTextConsts.liveReportSearchEntity = '(1 ${entity})';
/** @const */
bbTextConsts.liveReportSearchEntities = '(${count} ${entities})';

/** @const */
bbTextConsts.addIdeaToLiveReport = 'Add Idea to LiveReport';
/** @const */
bbTextConsts.structureCannotBeAddedToLiveReport = 'Structure cannot be added to LiveReport';
/** @const */
bbTextConsts.addEntitiesToLiveReport = 'Search and Add ${Entities}';
/** @const */
bbTextConsts.addDevicesToLiveReport = 'Search for Devices';

/*
 * File Upload Form
 */
/** @const */
bbTextConsts.validFileType = 'Valid file type';
/** @const */
bbTextConsts.validCSVUpload = 'Valid file type and column identifier chosen';
/** @const */
bbTextConsts.uploadFileMask = 'Uploading structures from ${filename} ...';
/** @const */
bbTextConsts.uploadEntityMask = 'Uploading entities from ${filename} ...';
/** @const */
bbTextConsts.uploadPropertiesMask = 'Uploading properties from ${filename} ...';
/** @const */
bbTextConsts.uploadExperimentMask = 'Uploading data from ${filename} ...';
/** @const */
bbTextConsts.importFile = 'Import file';
/** @const */
bbTextConsts.includeLabel = 'Include';
/** @const */
bbTextConsts.includeEntities = '${Entities}';
/** @const */
bbTextConsts.includeColumns = 'Columns';
/** @const */
bbTextConsts.maxFileSize = 'Max file size is ${size}.';
/** @const */
bbTextConsts.Mb = 'Mb';
/** @const */
bbTextConsts.uploadError = 'Error uploading file';
/** @const */
bbTextConsts.headerRequiredError = 'A header must be selected.';
/** @const */
bbTextConsts.successfulCSV = 'Successfully uploaded CSV data';
/** @const */
bbTextConsts.successfulSDF =
  'Successfully uploaded SDF data';
/** @const */
bbTextConsts.successfulMaestroFile =
  'Successfully uploaded Maestro data';
/** @const */
bbTextConsts.successfulExcelFile =
  'Successfully uploaded Excel data';
/** @const */
bbTextConsts.fromFileHeadersEmptyText =
  'Corporate ID or SMILES header';
/** @const */
bbTextConsts.fromFileHeadersFieldLabel =
  'Select the column which identifies the Corporate ID or SMILES pattern:';
/** @const */
bbTextConsts.fromFileFilePickInstructions =
  'Select an SDF, CSV, Excel or Maestro file';
/** @const */
bbTextConsts.reactantLiveReportFromFileFilePickInstructions =
  'Select an SDF or Maestro file';

/** @const */
bbTextConsts.valid = 'Valid';

/** @const */
bbTextConsts.pymolUsageMessage =
  'Please select one or more compounds in the LiveReport and one or more models to the right to view 3D';

//TODO(novak): this should be a template.
/** @const */
bbTextConsts.webmolLoadingMessage =
  '<h2>INITIALIZING PYMOL 3D</h2><br>If this is your first time using LiveDesign on this computer, you may have to wait a minute or two for Pymol to download.';

/** @const */
bbTextConsts.disabledActionButtonTooltipText =
  'Disabled because there is no open LiveReport or the open LiveReport is read-only.';
/** @const */
/** @const */
bbTextConsts.disabledSARButtonTooltipText =
  'Disabled because the active LiveReport is for ${entities}.';
/** @const */
bbTextConsts.disabledCommentsGadgetText = 'No ${Entities} Selected';

/** @const */
bbTextConsts.columnTooltipFormulaDescription = 'Formula Columns allow you to translate or combine the values of one or more columns in a LiveReport using standard arithmetical operators, built-in functions, and logical conditions.';

/*
 * Error Messages
 *
 * NOTE (jordan) some of these include templating in the form ${itemName}.  Currently we substitute into
 * these templates using string.replace('${itemName}', substitutionString).
 */
/** @const */
bbTextConsts.searchEntitiesError = 'There was an error searching for ${entities}';
/** @const */
bbTextConsts.searchLiveReportsError = 'There was an error searching for LiveReports';
/** @const */
bbTextConsts.createRecordError = 'There was an error creating the ${record type}.';
/** @const */
bbTextConsts.updateLiveReportColumnsError = 'There was an error updating the LiveReport';
/** @const */
bbTextConsts.copyLiveReportError = 'There was an error copying the LiveReport.';
/** @const */
bbTextConsts.readRecordsDataError = 'There was an error retrieving ${record type} data.';
/** @const */
bbTextConsts.readRecordsError =
  'There was an error retrieving the list of ${record type plural}.';
/** @const */
bbTextConsts.editRecordError =
  'There was an error updating the ${record type} ${record name}.';
/** @const */
bbTextConsts.deleteRecordError =
  'There was an error deleting the ${record type} ${record name}.';
/** @const */
bbTextConsts.addColumnError =
  'There was an error adding the column to the LiveReport.';
/** @const */
bbTextConsts.deleteColumnError =
  'There was an error removing the column from the LiveReport.<br/> Failed to remove column "${columnName}"';
/** @const */
bbTextConsts.removeColumnMessage =
  'Are you sure you want to remove column "${columnName}" from the LiveReport?';
/** @const */
bbTextConsts.removeAttachmentMessage =
  'Are you sure you want to delete the attachment for compound ${entityId}, column "${columnName}" ?';
/** @const */
bbTextConsts.removeColumnsMessage =
  'Are you sure you want to remove the selected columns from the LiveReport?';
/** @const */
bbTextConsts.removeColumnMessageTitle =
  'Confirm Remove Column';
/** @const */
bbTextConsts.removeAttachmentMessageTitle =
  'Confirm Deletion';
/** @const */
bbTextConsts.addCompoundError =
  'There was an error adding the ${entity} to the LiveReport.';
/** @const */
bbTextConsts.removeCompoundError =
  'There was an error removing the ${entity} from the LiveReport.';
/** @const */
bbTextConsts.addAllAssaysError =
  'There was an error adding all assays to the LiveReport.';
/** @const */
bbTextConsts.hideCompoundError =
  'There was an error updating the LiveReport\'s hidden ${entities}.';
/** @const */
bbTextConsts.freezeRowError =
  'There was an error updating the LiveReport\'s frozen rows.';
/** @const */
bbTextConsts.addCompoundNoLiveReportError =
  'You must have an open LiveReport to add a compound.';
/** @const */
bbTextConsts.uploadFileNoLiveReportError =
  'You must have an open LiveReport to upload a file.';
/** @const */
bbTextConsts.uploadFileNoProjectError =
  'You must have an open project to upload a file.';
/** @const */
bbTextConsts.addCompoundDetailsError = 'There was an error adding the compound: ';
/** @const */
bbTextConsts.searchCompoundsErrorResultCount = 'Please specify a valid "Max Results" value between ${minResults} and ${maxResults}';
/** @const */
bbTextConsts.emptySketcherError = 'Please sketch a structure above before searching.';
/** @const */
bbTextConsts.emptyIdTextAreaError = 'Please enter at least one ID in the search box.';
/** @const */
bbTextConsts.emptySearchForError = 'Please specify at least one entity type for searching';
/** @const */
bbTextConsts.invalidArgumentsError = 'invalid arguments detected.';
/** @const */
bbTextConsts.invalidColumnDescriptor =
  'There was an error retrieving the column descriptions.';
/** @const */
bbTextConsts.noButton = 'No';
/** #const */
bbTextConsts.yesButton = 'Yes';
/** @const */
bbTextConsts.noOpenLiveReportError =
  'There was an error ${action} the LiveReport: no open LiveReports found.';
/** @const */
bbTextConsts.incorrectLiveReportId =
  'The LiveReport being requested was not found or doesn\'t exist.';
/** @const */
bbTextConsts.incorrectLiveReportIdEdit =
  'There was an error updating the LiveReport: the LiveReport was not found or does not exist.';
/** @const */
bbTextConsts.liveReportExecutionError = 'There was an error opening the LiveReport.';
/** @const */
bbTextConsts.liveReportSaveAsTemplateError =
  'There was an error saving the LiveReport as a template.';
/** @const */
bbTextConsts.openLiveReportError = 'There was an error opening the LiveReport.';
/** @const */
bbTextConsts.fileImportError =
  'There was an error importing the file into the LiveReport.';
/** @const */
bbTextConsts.addAssayInvalidIdError =
  'There was an error adding assay data: invalid ID format.';
/** @const */
bbTextConsts.addExternalPropertyInvalidIdError =
  'There was an error adding external property data: Invalid ID format.';
/** @const */
bbTextConsts.addFavoriteAssayInvalidIdError =
  'There was an error adding assay to favorites: invalid ID format.';
/** @const */
bbTextConsts.sketcherCommunicationError =
  'There was an error communicating with the compound sketcher.';
/** @const */
bbTextConsts.sketcherUnableToCompleteActionHeader =
  'Unable to complete action';
/** @const */
bbTextConsts.sketcherAuthErrorDialogMsg =
  'There is something wrong with your current session and we need to log you out and back in to fix it.<br>' +
  'Click OK to be logged out and redirected to the login page.<br>' +
  'Click Cancel to continue to your session without correcting the issue.';
/** @const */
bbTextConsts.copyToSketcherError =
  'There was an error copying the compound: could not communicate with the sketcher.';
/** @const */
bbTextConsts.copyStructureError =
  'There was an error retrieving the structure.';
/** @const */
bbTextConsts.getMonitorIdError =
  'There was an error getting a Monitor ID from Plexus.';
/** @const */
bbTextConsts.pldbCommunicationError =
  'There was an error communicating with the PLDB Server.';
/** @const */
bbTextConsts.retrieveCompoundStructureError =
  'There was an error retrieving the compound structure.';
/** @const */
bbTextConsts.pymolCommunicationError =
  'There was an error communicating with the PyMOL Visualizer.';
/** @const */
bbTextConsts.noCorporateIdBadDataError =
  'There was an error retrieving the LiveReport: some rows are missing corporate IDs.';
/** @const */
bbTextConsts.duplicateColumnHeaderBadDataError =
  'There was an error retrieving the LiveReport, duplicated column header: ';
/** @const */
bbTextConsts.noModelFolderError =
  'There was an error retrieving ${model} folder: unable to find folder or folder doesn\'t exist.';
/** @const */
bbTextConsts.createLiveReportNoProjectError =
  'You must have an open project to create a LiveReport.';
/** @const */
bbTextConsts.invalidStringFormat =
  'Invalid argument type passed. Must be a string.';
/** @const */
bbTextConsts.invalidFieldCharacters =
  'Invalid characters for field.';
/** @const */
bbTextConsts.beforeYouContinue = 'We\'ll need a few things before you continue.';
/** @const */
bbTextConsts.missingCommentToEdit =
  'We were unable to find the comment you are trying to edit.';
/** @const */
bbTextConsts.fileFieldNotFound =
  'An Ext.form.field.File was not found in the passed in form.';
/** @const */
bbTextConsts.invalidMaximumNumberOfCompounds =
  'The maximum number of results has to be a number larger than 0';
/** @const */
bbTextConsts.missingPrimaryDatabase =
  'There was an error retrieving the primary database.';
/** @const */
bbTextConsts.missingFavoriteAssay =
  'There was an error retrieving the favorite assay.';
/** @const */
bbTextConsts.invalidFilterType = 'Invalid filter type selected. Available type: ${filter_types}';

// NOTE (jordan) if we ever run into this in the wild we should fix it to show a real error.
/** @const */
bbTextConsts.genericError =
  'There was an unknown error in the application. Please see details below.';
/** @const */
bbTextConsts.invalidProjectError =
  'The project with ID ${projectId} does not exist or is currently not accessible.  ' +
  'Please ensure that the project ID is valid or contact your system administrator.  ';
/** @const */
bbTextConsts.checkAsyncResponseError =
  'An error occurred while checking the status of a pending import.';

/** @const */
bbTextConsts.exportFailed =
  'There was an error exporting the selected files.';
/** @const */
bbTextConsts.exporterExists =
  'The selected files are already being downloaded.';
/** @const */
bbTextConsts.versionReadError =
  'There was an error retrieving the version data.';
/** @const */
bbTextConsts.offlineError =
  'You are currently offline.  Changes made offline may not be saved.';

/** @const */
bbTextConsts.pymolContents = 'Contents';
/** @const */
bbTextConsts.annotations = 'Annotations';
/** @const */
bbTextConsts.pymolStyles = 'Styles';
/** @const */
bbTextConsts.pymolCompoundStyles = 'Compound Styles';
/** @const */
bbTextConsts.pymolTargetStyles = 'Protein Styles';
/** @const */
bbTextConsts.pymolBindingStyles = 'Binding Site Styles';
/** @const */
bbTextConsts.pymolSolventStyle = 'Water/Metal Styles';
/** @const */
bbTextConsts.pymolSticks = 'Sticks';
/** @const */
bbTextConsts.pymolBallAndStick = 'Ball & Stick';
/** @const */
bbTextConsts.pymolSpheres = 'Spheres';
/** @const */
bbTextConsts.pymolSurface = 'Surface';
/** @const */
bbTextConsts.pymolHbonds = 'Show H-bonds';
/** @const */
bbTextConsts.pymolhideH = 'Show nonpolar H';
/** @const */
bbTextConsts.pymolCartoon = 'Cartoon/Ribbon';
/** @const */
bbTextConsts.pymolLines = 'Lines';
/** @const */
bbTextConsts.pymolBfactor = 'B-Factor Putty';
/** @const */
bbTextConsts.pymolSameAsTarget = 'Same as Protein';
/** @const */
bbTextConsts.pymolLinesDetailed = 'Lines (detailed)';
/** @const */
bbTextConsts.pymolResidue = 'Show residue labels';
/** @const */
bbTextConsts.pymolOff = 'Off';
/** @const */
bbTextConsts.targetNameDrugDiscovery = 'Protein/Other';
/** @const */
bbTextConsts.targetNameMaterialsScience = 'Other';
/** @const */
bbTextConsts.PyMolShowTarget = '${targetName}';
/** @const */
bbTextConsts.pymolAtomicSurface = 'Atomic';
/** @const */
bbTextConsts.pymolElectrostaticSurface = 'Electrostatic';
/** @const */
bbTextConsts.webmolExportBegan = 'Your 3D export is underway.';
/** @const */
bbTextConsts.adding = 'Adding';
/** @const */
bbTextConsts.addColumnMessage = 'Adding column';
/** @const */
bbTextConsts.addColumnsMessage = 'Adding columns';
/** @const */
bbTextConsts.addAllColumnsMessage = 'Adding all columns';
/** @const */
bbTextConsts.removeColumnStatusMessage =
  'Removing column "${columnName}" from the LiveReport...';
/** @const */
bbTextConsts.updateLiveReportMask =
  'Updating LiveReport...';
/** @const */
bbTextConsts.addCompoundsMask =
  'Adding ${entity}(s)...';
/** @const */
bbTextConsts.addSketchedCompound =
  'Adding sketched compound to LiveReport...';
/** @const */
bbTextConsts.enumeratedCompound =
  'Multiple matches were found, or the compound contains unspecified stereocenters which have been enumerated. Adding %d results.';
/** @const */
bbTextConsts.removeCompoundsMask =
  'Removing ${entity}(s)...';
/** @const */
bbTextConsts.showHiddenCompoundsMask =
  'Showing hidden ${entity}(s)...';
/** @const */
bbTextConsts.updateFrozenRows =
  'Updating frozen rows(s)...';
// NOTE (wong) Keep in sync with the string const ROW_COUNT_EXCEEDED in the BE (currently in LiveReportPatchApplier.java)
/** @const */
bbTextConsts.rowCountExceeded = 'Row Count Exceeded';
/** @const */
bbTextConsts.oneOrMoreAddedCompoundsHidden =
  'One or more of the ${entities} added are hidden, or are hidden due to your filter settings.';
/** @const */
bbTextConsts.oneOrMoreCompoundsNotRemoved =
  'One or more of the ${entities} were not removed. You may have advanced search with auto-update on.';
/** @const */
bbTextConsts.hideCompoundsMask =
  'Hiding ${entity}(s)...';
/** @const */
bbTextConsts.sortingTip =
  'Sorting. TIP: Hold Shift while double clicking columns to add secondary sort(s).';
/** @const */
bbTextConsts.sortingTipNonAveragedColumns =
  'Sorting only uses the first value for assay columns that are not averaged. Switch to Row Per Experiment mode to apply sort on all values.';
/** @const */
bbTextConsts.makeHidden =
  'Make Hidden';
/** @const */
bbTextConsts.makeVisible =
  'Make Visible';
/** @const */
bbTextConsts.duplicateLiveReport =
  'Duplicate...';
/** @const */
bbTextConsts.makePublic =
  'Publish';
bbTextConsts.publishColumnMessage =
  'Are you sure you want to make "${name}" public?';
/** @const */
bbTextConsts.publishColumnMessageTitle =
  'Confirm ' + bbTextConsts.makePublic;
/** @const */
bbTextConsts.handleLiveReportVisibilityTitle =
  'Make LiveReport {visibility}';
/** @const */
bbTextConsts.duplicateLiveReportTitle =
  'Duplicate LiveReport';
/** @const */
bbTextConsts.fileExportDialogTitle =
  'Export LiveReport';
/** @const */
bbTextConsts.duplicatingLiveReport =
  'Duplicating LiveReport';
/** @const */
bbTextConsts.creatingNewLiveReportFromTemplate =
  'Creating New LiveReport from Template';
/** @const */
bbTextConsts.liveReportTitleFieldLabel =
  'LiveReport name:';
/** @const */
bbTextConsts.hidden =
  'Hidden';
/** @const */
bbTextConsts.visible =
  'Visible';
/** @const */
bbTextConsts.hideLiveReportDialogText =
  'Are you sure that you want to make the LiveReport "{liveReportTitle}" hidden?\n\nOnce hidden, it will not appear in the LiveReport Manager for other users (except for Project Admins and Admins).\n\nAnyone who navigates to the URL of the LiveReport will still be able to open it.';
/** @const */
bbTextConsts.showLiveReportDialogText =
  'Are you sure that you want to make the LiveReport "{liveReportTitle}" visible?\n\nOnce visible, it will appear in the LiveReport Manager for all users.';
/** @const */
bbTextConsts.handleVisibilityLiveReportNoId = 'Attempted to change the visibility of a LiveReport with no id.';
/** @const */
bbTextConsts.privateLiveReports = 'Private LiveReports';
/** @const */
bbTextConsts.sharedProjects = 'Shared Projects';
/** @const */
bbTextConsts.tagTreeRootId = 'root';
/** @const */
bbTextConsts.readOnly =
  'Read-Only';
/** @const */
bbTextConsts.editable =
  'Editable';
/** @const */
bbTextConsts.makeReadOnly =
  'Make Read-Only';
/** @const */
bbTextConsts.makeEditable =
  'Make Editable';
/** @const */
bbTextConsts.makeReadOnlyDialogTitle =
  'Make LiveReport {readOnly}';
/** @const */
bbTextConsts.makeReadOnlyDialogText =
  'Are you sure that you want to make the LiveReport "{liveReportTitle}" read-only to other users?';
/** @const */
bbTextConsts.makeEditableDialogText =
  'Are you sure that you want to make the LiveReport "{liveReportTitle}" editable by other users?';
/** @const */
bbTextConsts.makeReadOnlyNoId = 'Attempted to change the read-only level of a LiveReport with no id.';
/** @const */
bbTextConsts.copyToProject = 'Copy to Project...';
/** @const */
bbTextConsts.pose = 'Pose';
/** @const */
bbTextConsts.showPoses = 'Show Poses';
/** @const */
bbTextConsts.showNumberPose = '%d pose';
/** @const */
bbTextConsts.showNumberPoses = bbTextConsts.showNumberPose + 's';
/** @const */
bbTextConsts.closePoses = 'Close Poses';
/** @const */
bbTextConsts.showPosesInLiveReport =
  bbTextConsts.showPoses + ' in ' + bbTextConsts.liveReport;
/** @const */
bbTextConsts.closePosesInLiveReport =
  bbTextConsts.closePoses + ' in ' + bbTextConsts.liveReport;
/** @const */
bbTextConsts.showPosesTooltip = 'Display a separate row for each pose on any compound that has any poses data available.  Useful for comparing pose properties, viewing pose-specific 3D, and more.';
/** @const */
bbTextConsts.closePosesTooltip = 'Hide all rows containing pose-specific data and display a single row per compound.';
/** @const */
bbTextConsts.addTagError =
  'There was an error adding the folder to the project.';
/** @const */
bbTextConsts.moveLiveReportToFolder =
  'Move LiveReport to Folder';
/** @const */
bbTextConsts.addNewTag = 'Create a new folder for LiveReports';
/** @const */
bbTextConsts.renameTag = 'Rename folder';
/** @const */
bbTextConsts.moveToFolder = 'Move to Folder...';
/** @const */
bbTextConsts.subscribeToNotifications = 'Subscribe to Notifications';
/** @const */
bbTextConsts.unsubscribeToNotifications = 'Unsubscribe from Notifications';
/** @const */
bbTextConsts.subscribedMessage = 'Notifications activated. Sending daily email digests to ';
/** @const */
bbTextConsts.subscribedFailureMessage = 'Cannot subscribe to email digests. No email associated with account.';
/** @const */
bbTextConsts.template = 'Template';
/** @const */
bbTextConsts.placeInFolder = 'Place In Folder:';
/** @const */
bbTextConsts.projectHome = 'Project Home';
/** @const */
bbTextConsts.activeProjectHomeFolder = '${activeProjectName} Home';
/** @const */
bbTextConsts.createANewFolder = 'Create a New Folder';
/** @const */
bbTextConsts.black = 'black';
/** @const */
bbTextConsts.blank = 'Blank';
/** @const */
bbTextConsts.createNewTemplateText = 'Create New Template';
/** @const */
bbTextConsts.creatingNewTemplate = 'Creating New Template ';
/** @const */
bbTextConsts.deleteTemplateMenuItem = 'Delete...';
/** @const */
bbTextConsts.deleteTemplateDialogTitle = 'Delete Existing Template';
/** @const */
bbTextConsts.deleteTemplateDialogText = 'Choose the template that should be deleted:';
/** @const */
bbTextConsts.deleteTemplateConfirmationTitle = 'Confirm Delete Existing Template';
/** @const */
bbTextConsts.deleteTemplateConfirmationText = 'Are you sure you want to delete the template "{templateName}"?';
/** @const */
bbTextConsts.manageTemplates = 'Manage Templates';
/** @const */
bbTextConsts.overwritingTemplate = 'Overwriting template ';
/** @const */
bbTextConsts.overwriteTemplateMenuItem = 'Overwrite Existing...';
/** @const */
bbTextConsts.overwriteTemplateDialogTitle = 'Overwrite Existing Template';
/** @const */
bbTextConsts.overwriteTemplateDialogText = 'Choose the template that should be overwritten by the currently opened LiveReport:';
/** @const */
bbTextConsts.overwriteTemplateConfirmationTitle = 'Confirm Overwite Existing Template';
/** @const */
bbTextConsts.overwriteTemplateConfirmationText = 'Are you sure you want to overwrite the template "{templateName}"?';
/** @const */
bbTextConsts.overwriteTemplateWarning = 'Warning! This action cannot be undone.\n\nYou are not the owner of this template. The updated template will no longer be accessible by the original owner.\nTo avoid this, create a new template instead via "Save as New".\n\nAre you sure you want to overwrite the template "{templateName}"?';
/** @const */
bbTextConsts.applyTemplate = 'Apply...';
/** @const */
bbTextConsts.applyTemplateDialogTitle = 'Apply Template';
/** @const */
bbTextConsts.applyTemplateDialogText = 'Choose a template to apply to this LiveReport:';
/** @const */
bbTextConsts.applyTemplateConfirmationTitle = 'Confirm Apply Template';
/** @const */
bbTextConsts.applyTemplateWarningBody = 'Warning! This action cannot be undone.\n\nTo preserve this LiveReport, first create a duplicate, and then apply the template to the duplicated LiveReport.\n\nAre you sure you want to overwrite this LiveReport with the "{templateName}" template?';
/** @const */
bbTextConsts.saveAsNewTemplateMenuItem = 'Save as New...';
/** @const */
bbTextConsts.templateName = 'Template Name';
/** @const */
bbTextConsts.setDefaultTemplate = 'Change Project Default...';
/** @const */
bbTextConsts.defaultTemplateText = 'Choose the template that should be shown as the default template option to any user that creates a new LiveReport inside this project';
/** @const */
bbTextConsts.unknownRecordType = 'Record';

// External Property Display Names for combobox
/** @const */
bbTextConsts.genericAttachment = 'Attachment';
/** @const */
bbTextConsts.genericAttachments = bbTextConsts.genericAttachment + 's';
/** @const */
bbTextConsts.highlightedSubstructure = 'Highlighted Substructure';
/** @const */
bbTextConsts.modelPose = 'Model Pose';
/** @const */
bbTextConsts.reportLevelCompoundTooltip = '(Default) Show one row per compound.  Information from across all lots and salts will be aggregated together.';
/** @const */
bbTextConsts.reportLevelCompoundLotTooltip = 'Show one row for each lot / batch / sample of a compound.  Information from across all salts on that lot will be aggregated together.';
/** @const */
bbTextConsts.reportLevelCompoundLotSaltTooltip = 'Show one row for each salt of each lot / batch / sample of a compound.  Information from across all recorded observations on that salt will be aggregated together.';
/** @const */
bbTextConsts.reportLevelCompoundSaltTooltip = 'Show one row for each salt of a compound, across all lots / salts / samples on that compound.';
/** @const */
bbTextConsts.categorical = 'Categorical';
/** @const */
bbTextConsts.range = 'Range';
/** @const */
bbTextConsts.statusError = 'Status: ${status}';
/** @const */
bbTextConsts.codeError = 'Code: ${code}';
/** @const */
bbTextConsts.noAddAllToEmptyLR = 'Add compounds to the current LiveReport before trying to add \'All Assays\'';
/** @const */
bbTextConsts.serviceUnavailable = '  Please try again later.';
/** @const */
bbTextConsts.inactivityError = 'For security reasons, you have been automatically logged out due to inactivity';
/** @const */
bbTextConsts.samlLoginError = 'An error occurred while processing your log-in from an external source.  Please contact your system administrator.';
/** @const */
bbTextConsts.addFavoriteError =
  'There was an error trying to favorite the column.';
/** @const */
bbTextConsts.removeFavoriteError =
  'There was an error trying to remove the favorited column.';
/** @const */
bbTextConsts.reopenPopoutGadgetsText = 'This LiveReport used to have open popout windows.';
/** @const */
bbTextConsts.reopenPopoutGadgetsLink = '[Restore Popouts]';
/** @const */
bbTextConsts.openPopoutError = 'One or more popouts failed to open, please check browser settings';
/** @const */
bbTextConsts.numericInputPlaceholder = 'Enter number (ex: 3 or 6${separator}73)';
/** @const */
bbTextConsts.assayLimitedAddableColumn = 'Limited Assay Addable Column';
/** @const */
bbTextConsts.assayLimitedAddableColumnGroups = 'Limited Assay Addable Column Groups';
/** @const */
bbTextConsts.assayLimitedAddableColumns = `${bbTextConsts.assayLimitedAddableColumn}s`;
/** @const */
bbTextConsts.selectDate = 'Select Date';
/** @const */
bbTextConsts.showAsText = 'Show as Text';
/** @const */
bbTextConsts.showAsRange = 'Show as Range';
/** @const */
bbTextConsts.autosuggestPlaceholder = 'Enter acceptable value(s) for this property. Use (defined) to capture any non-blank values in models and assays.';
/** @const */
bbTextConsts.added = 'Added';
/** @const */
bbTextConsts.predictor = 'Predictor';
/** @const */
bbTextConsts.removed = 'Removed';
/** @const */
bbTextConsts.predictors = `${bbTextConsts.predictor}s`;
/** @const */
bbTextConsts.row = 'row';
/** @const */
bbTextConsts.predictorTrainingRun = 'Predictor Training Run';
/** @const */
bbTextConsts.rows = `${bbTextConsts.row}s`;
/** @const */
bbTextConsts.predictorTrainingRuns = `${bbTextConsts.predictorTrainingRun}s`;
/** @const */
bbTextConsts.viewDocumentation = 'View Documentation';
/** @const */
bbTextConsts.dropdownPlaceholderText = 'Click to add values';
/** @const */
bbTextConsts.readOnlyFeatureTooltipText = 'Action unavailable';
/** @const */
bbTextConsts.childRowDisabledFeatureTooltipText = 'Action disabled for child entities';
/** @const */
bbTextConsts.closeAllLiveReportsTitle = 'Close All LiveReports';
/** @const */
bbTextConsts.closeAllLiveReportsText = 'Are you sure you want to close all open LiveReports?';
/** @const */
bbTextConsts.warning = 'Warning';
export default bbTextConsts;
