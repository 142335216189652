import ApiEndpoints from 'bb/enums/ApiEndpoints';
import RequestMethod from 'bb/enums/RequestMethod';
import bbConsts from 'bb/util/consts';
import bbTextConsts from 'bb/util/textConsts';
import environment from 'bb/util/environment';
goog.require('goog.Uri');
goog.require('goog.dom');
goog.require('goog.events');
goog.require('goog.events.EventType');
goog.require('goog.events.KeyCodes');
goog.require('goog.json');
goog.require('goog.net.XhrIo');
goog.require('goog.string');

const login = {};

/**
 * Main entry point for the LiveDesign login page.
 * @export
 */
goog.global.main = function() {
  const loginConfig = {
    companyLogo: null,
    companyName: null,
  };

  if (goog.isDefAndNotNull(loginConfig.companyLogo)) {
    document.getElementById('customerImg').src = loginConfig.companyLogo;
  } else {
    document.getElementById('customerImg').src = bbConsts.TRANSPARENT_IMAGE_SRC;
  }
  if (!goog.string.isEmptySafe(loginConfig.companyName)) {
    document.getElementById('customerName').innerHTML = loginConfig.companyName;
  }

  // Drop rd query param if present
  let url = new URL(location.href);
  const redirect = url.searchParams.get('rd');
  if (redirect) {
    url.searchParams.delete('rd');
    window.history.replaceState({}, '', url);
  }

  // Show idleness logout message if needed
  const uri = new goog.Uri(window.location);
  const idle = uri.getParameterValue('idle');
  const samlError = uri.getParameterValue('samlError');
  if (idle) {
    document.getElementById('errorMsg').innerHTML = bbTextConsts.inactivityError;
  }
  if (samlError) {
    document.getElementById('errorMsg').innerHTML = bbTextConsts.samlLoginError;
    document.getElementById('username').style.display = 'none';
    document.getElementById('password').style.display = 'none';
    document.getElementById('loginButton').style.display = 'none';
    [...document.getElementsByClassName('login-icon')].forEach( e => e.style.display = 'none');
  }

  const loginHandler = function() {
    /*
     * TODO(cheng): Move all auth logic to a common API endpoint for logging in and out, then
     *              compile against that library.
     */
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    document.getElementById('loading').classList.remove('hidden');
    document.getElementById('errorMsg').innerText = '';

    /* Handler for login success.  */
    const authHandler = function(warningMessage) {
      if (!_.isEmpty(warningMessage)) {
        window.sessionStorage.setItem(bbConsts.LOGIN_WARNING, warningMessage);
      }

      let redirectUri = new goog.Uri(window.location);
      if (redirect) {
        redirectUri = new goog.Uri(redirect);
      }

      const currPath = redirectUri.getPath();
      const endIndex = currPath.lastIndexOf('static');
      if (endIndex !== -1) {
        redirectUri.setPath(currPath.slice(0, endIndex));
      }
      redirectUri.removeParameter('idle');
      redirectUri.setFragment('');
        // /* Now that the user is authenticated, we forward them on to the next page. */
      if (window.location !== redirectUri) {
        window.location = redirectUri;
      }
      else {
          /* If no changes were made to *redirectUri*, we need to force a page reload so that the
           * user re-requests the current page using their now-active session token. */
        window.location.reload(true);
      }
    };

    /* Login for Live Design */
    const liveDesignLogin = new Promise((resolve, reject) => {
      const loginUrl = environment.asBoolean(environment.SettingKey.USE_AUTHN_SERVICE_FOR_USER_LD_LOGIN) ? bbConsts.AUTHN_SERVICE_LOGIN_ENDPOINT : bbConsts.WEBSERVICE_ROOT + ApiEndpoints.LOGIN;
      goog.net.XhrIo.send(
          loginUrl,
            event => {
              document.getElementById('loading').classList.add('hidden');
              const xhr = /** @type {goog.net.XhrIo} */ (event.target);
              if (xhr.getStatus() === 401) {
                // Auth error
                document.getElementById('errorMsg').innerHTML = xhr.getResponse();
                /*
                 * SS-8048: disabling this message until we find a backend fix to it appearing constnatly
                 } else {
                 // Connection error
                 document.getElementById('errorMsg').innerHTML = 'There was an error connecting to the server.';
                 */
              }
              if (xhr.isSuccess()) {
                const warningMessage = xhr.getResponse();
                resolve(warningMessage);
              } else {
                reject(new Error('LiveDesign login failed'));
              }
            },
            RequestMethod.POST.name,
            'username=' + goog.string.urlEncode(username) + '&' +
            'password=' + goog.string.urlEncode(password), {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
        );
    });

    liveDesignLogin.then(authHandler);
  };

  goog.events.listen(document.getElementById('loginButton'), goog.events.EventType.CLICK, loginHandler);

  const passwordField = document.getElementById('password');
  if (!environment.asBoolean(environment.SettingKey.DISABLE_PASSWORD_AUTOCOMPLETE)) {
    document.getElementById('loginForm')['autocomplete'] = 'on';
    passwordField['autocomplete'] = 'on';
  }

  goog.events.listen(passwordField, goog.events.EventType.KEYPRESS, event => {
    if (event.keyCode === goog.events.KeyCodes.ENTER) {
      document.getElementById('loginButton').click();
    }
  });

  if (environment.asBoolean(environment.SettingKey.ENABLE_PASSWORD_RESET)) {
    const pwmURL = environment.asString(environment.SettingKey.RESET_PASSWORD_LINK).trim() || bbConsts.RESET_PASSWORD_LINK;
    document.getElementById('reset-password-container').style.display = 'block';
    document.getElementById('reset-password').href = pwmURL;
    document.getElementById('reset-password').target = bbConsts.NEW_TAB;
  }

  const disclaimerFooter = environment.asString(environment.SettingKey.LOGIN_DISCLAIMER_FOOTER);
  if (goog.isDefAndNotNull(disclaimerFooter)) {
    goog.dom.setTextContent(document.getElementById('footer'), disclaimerFooter);
  }

  //Sets the placeholder for password field
  document.getElementById('password').placeholder = 'Password';
};
export { login };
